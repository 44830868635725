p,
body,
label,
button,
textarea {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Inter, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 24px;
  margin: 0;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-family: Inter, sans-serif;
}

*::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-button {
  display: none;
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
}

*::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

*::-webkit-scrollbar-thumb:active {
  background: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-track:hover {
  background: transparent;
}

*::-webkit-scrollbar-track:active {
  background: transparent;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}
